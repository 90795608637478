import React, { useState, useEffect, useMemo } from 'react';
import { utcFormatFromString } from '../../../helpers/timeFormatter';
// import { useTranslation } from 'react-i18next';
import { UserType } from '../../../adapters/UserAdapter';
import ContentsModal from './DisturbanceMessageContentsModal';
import ActionModal from './DisturbanceMessageActionModal';
import DisturbanceTypeBadge from '../../../components/DisturbanceTypeBadge';
import styles from './DisturbanceMessageTable.module.css';
import Pagination from '../../../components/Pagination/Pagination';
import { Toast, useToast } from '@cd/sdds-common-components-react';

import '../../../styles/sdds-filler-data-table.css';
import { t } from 'i18next';
import { TdsTooltip } from '@scania/tegel-react';

type DisturbanceMessageTableProps = {
  messages: Array<DisturbanceMessageType>;
  users: Array<UserType>;
  paginationReset: boolean;
};

const DisturbanceMessageTable = (props: DisturbanceMessageTableProps) => {

  const { messages, users, paginationReset } = props;
  const [messagesExtended, setMessagesExtended] = useState<Array<DisturbanceMessageTableType>>([]);
  const [modalMessageContents, setModalMessageContents] = useState(Array<DisturbanceMessageContentType>());
  const [showContentsModal, setShowContentsModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [messageSystem, setMessageSystem] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalData, setActionModalData] = useState<DisturbanceMessageActionDataType>();
  const [tableSorting, setTableSorting] = useState<TableSortingType>({ column: 'createdTime', order: 'desc' });
  const { toastQueue, addToast } = useToast();

  const getStatus = (startTime: string, endTime: string): string => {
    const start = new Date(startTime).getTime();
    const end = endTime ? new Date(endTime).getTime() : null;
    const now = new Date().getTime();
    if (start > now) {
      // Pending, not yet started
      return 'Pending';
    }
    if (end && end < now) {
      // Expired
      return 'Expired';
    }
    // Active
    return 'Active';
  };

  const getUserFullName = (scaniaId: string): string => {
    if (scaniaId !== null) {
      const foundUser = users.find(user => user.scaniaId.toLowerCase() === scaniaId.toLowerCase());
      return foundUser ? `${foundUser.fullName} (${foundUser.scaniaId})` : scaniaId;
    }
    return scaniaId;
  };

  const getMessageTitle = (messageContents: Array<DisturbanceMessageContentType>): string => {
    const englishMessage = messageContents.find(content => content.cultureCode === 'en-GB');
    return englishMessage ? englishMessage.header : t("TS_temp:NotPublishedInEnglish");
  };

  const setContentsModalData = (message: DisturbanceMessageTableType): void => {
    setModalMessageContents(message.messageContents);
    setMessageSystem(message.systemName);
    setMessageType(message.type)
    setShowContentsModal(true);
  };

  const updateActionModalData = (message: DisturbanceMessageTableType, action: DisturbanceMessageModalActionType): void => {
    setActionModalData({ message, action });
    setShowActionModal(true);
  };

  const handleActionEvent = (event: DisturbanceMessageActionEventType): void => {
    setShowActionModal(false);
    if (!event.success) {
      addToast({ text: t("TS_temp:ActionFailed"), type: 'error' });
      return;
    }

    if (event.action === 'cancel') {
      setMessagesExtended(messagesExtended.filter(x => x.id !== event.id));
      addToast({ text: t("TS_temp:DisturbanceMessageCancelled"), type: 'info' });
      setTableSorting({ column: 'createdTime', order: 'desc' })
    }
    if (event.action === 'stop') {
      const message = messagesExtended.find(x => x.id === event.id);
      if (message) {
        message.endTime = utcFormatFromString('now');
        message.status = getStatus(message.startTime, message.endTime);
        setMessagesExtended([...messagesExtended.filter(x => x.id !== event.id), message]);
        addToast({ text: t("TS_temp:DisturbanceMessageStopped"), type: 'info' });
        setTableSorting({ column: 'createdTime', order: 'desc' })
      }
    }
  };

  const sortTableByColumn = (column: string) => {
    let order = (tableSorting.order === 'asc') ? 'desc' : 'asc';
    setTableSorting({ column, order: order as OrderType });
  };

  useEffect(() => {
    setMessagesExtended(messages
      .map((message: DisturbanceMessageType) => ({
        ...message,
        startTime: utcFormatFromString(message.startTime),
        endTime: utcFormatFromString(message.endTime),
        status: getStatus(message.startTime, message.endTime),
        title: getMessageTitle(message.messageContents)?.replace('Invalid Date', '[N/A]  '),
      })));
    setTableSorting({ column: 'createdTime', order: 'desc' })
  }, [messages]);

  useEffect(() => {
    if (messagesExtended.length === 0) return;

    let copyOfMessagesExtended = [...messagesExtended];
    let sortedMessagesExtended: Array<DisturbanceMessageTableType> = copyOfMessagesExtended.sort((a, b) => {
      let first = a[tableSorting.column as keyof DisturbanceMessageTableType];
      let second = b[tableSorting.column as keyof DisturbanceMessageTableType];
      // eslint-disable-next-line prefer-const
      [first, second] = (tableSorting.order === 'asc') ? [first, second] : [second, first];
      if (first < second) return -1;
      if (first > second) return 1;
      return 0;
    });
    setMessagesExtended(sortedMessagesExtended);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSorting]);

  let PageSize = 10;


  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return messagesExtended.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, messagesExtended, PageSize]);


  useEffect(() => {
    setCurrentPage(1)
  }, [paginationReset])

  return (
    messagesExtended && (
      <>
        <table className="table">
          <thead>
            <tr>
              <th
                className={styles.statusField}
              >{t("TS_core:Status")}</th>
              <th
                onClick={() => sortTableByColumn('type')}
                className={styles.typeFieldSize}
              >
                {t("TS_core:Type")}

              </th>
              <th >{t("Title")}</th>
              <th
                onClick={() => sortTableByColumn('systemName')}
                className={styles.systemFieldsize}
              >{t("TS_core:SystemName")}
                {(tableSorting.column === 'systemName' && tableSorting.order === 'asc') ? <span className={styles.sortingArrow}> &#x25B2;</span> : <span className={styles.sortingArrow}> &#x25BC;</span>}
              </th>
              <th
                onClick={() => sortTableByColumn('startTime')}
                className={styles.timeFieldSize}
              >
                {t("TS_core:StartTime")} (UTC)
                {(tableSorting.column === 'startTime' && tableSorting.order === 'asc') ? <span className={styles.sortingArrow}> &#x25B2;</span> : <span className={styles.sortingArrow}> &#x25BC;</span>}
              </th>
              <th onClick={() => sortTableByColumn('endTime')}
                className={styles.timeFieldSize}>
                {t("TS_core:EndTime")} (UTC)
                {(tableSorting.column === 'endTime' && tableSorting.order === 'asc') ? <span className={styles.sortingArrow}> &#x25B2;</span> : <span className={styles.sortingArrow}> &#x25BC;</span>}
              </th>

              <th
                className={styles.createdByFieldSize}
              >
                {t("CreatedBy")}</th>
              <th onClick={() => sortTableByColumn('createdTime')}
                className={styles.timeFieldSize}>{t("TS_core:CreatedTime")} (UTC)
                {(tableSorting.column === 'createdTime' && tableSorting.order === 'asc') ? <span className={styles.sortingArrow}> &#x25B2;</span> : <span className={styles.sortingArrow}> &#x25BC;</span>}
              </th>
              <th
                className={styles.actionFieldSize}
              ></th>
            </tr>
          </thead>
          <tbody>

            {
              currentTableData.length > 0 && currentTableData.map((message: DisturbanceMessageTableType, index) => (

                <tr key={message.id}>

                  <td>
                    {message.status === 'Active' ? <sdds-icon name="scania-sound" class={styles.settingsIconSize} /> : ''}{message.status}
                  </td>
                  <td><DisturbanceTypeBadge type={message.type} index={index} /></td>
                  <td>
                    <div
                      data-testid={`disturbance-message-contents-${index}`}
                      role="link"
                      tabIndex={0}
                      id={`disturbance-message-contents-${index}`}
                      className={styles.makeClickableLink}
                      onClick={() => setContentsModalData(message)}
                      onKeyDown={() => setContentsModalData(message)}
                    >
                      <TdsTooltip text="View message" selector={`#disturbance-message-contents-${index}`} placement='top' ></TdsTooltip>
                      {message.title}
                    </div>
                  </td>
                  <td>{message.systemName}</td>
                  <td>{message.startTime}</td>
                  <td>{message.endTime}</td>
                  <td>{getUserFullName(message.createdBy)}</td>
                  <td>{utcFormatFromString(message.createdTime)}</td>

                  <td>
                    {(message.status === 'Active' || message.status === 'Pending') && (
                      <button
                        data-testid={`disturbance-message-action-modal-button-${index}`}
                        type="button"
                        className={"sdds-btn sdds-btn-sm sdds-btn-secondary"}
                        id={`disturbance-message-action-modal-button-${index}`}
                        onClick={() => updateActionModalData(message, message.status === 'Active' ? 'stop' : 'cancel')}
                      >
                        <TdsTooltip text="Stop publishing" selector={`#disturbance-message-action-modal-button-${index}`} placement='top'></TdsTooltip>

                        {t("Stop")}  {/* <sdds-icon name="scania-cross" class={styles.settingsIconSize} />  */}
                      </button>
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={messagesExtended.length}
          pageSize={PageSize}
          onPageChange={(page: any) => setCurrentPage(page)}
        />
        <ContentsModal show={showContentsModal} contents={modalMessageContents} system={messageSystem} type={messageType} onClose={setShowContentsModal} />
        <ActionModal show={showActionModal} data={actionModalData} onAction={handleActionEvent} onClose={setShowActionModal} />
        <Toast toastQueue={toastQueue} />
      </>
    ));
};

export default DisturbanceMessageTable;

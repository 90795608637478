import React from 'react';
import styles from './DisturbanceTypeBadge.module.css';
import { TdsTooltip } from '@scania/tegel-react';

const DisturbanceTypeBadge = (props: { type: string, index?: number }) => {
  const { type, index } = props;

  const badgeTypeClass = (): string => {
    switch (type) {
      case 'Error': return 'badgeDanger';
      case 'Warning': return 'badgeWarning';
      case 'Solved': return 'badgeOK';
      default: return '';
    }
  };
  const badgeImage = (): string => {
    switch (type) {
      case 'Error': return '../../../../assets/error.svg';
      case 'Warning': return '../../../../assets/warning.svg';
      case 'Solved': return '../../../../assets/tick.svg';
      default: return '../../../../assets/info.svg';
    }
  };

  return (
    <div>
      <TdsTooltip text={type} selector={`#${type}${index}`} placement='top'></TdsTooltip>
      <div className={styles.iconWrapper}>
        <img id={`${type}${index}`} src={badgeImage()} alt={type} className={`${styles.badge} ${styles[badgeTypeClass()]}`} />
      </div>
    </div>

  );
};

export default DisturbanceTypeBadge;
